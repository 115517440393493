import moment from 'moment'
import { defineStore } from 'pinia'

import { getAnalyticsData } from 'lib/api/modules/streamer/campaign-analytics'
import { ICampaignAnalytics, ICampaignAnalyticsDay } from 'lib/types/campaign-analytics'
import { ICampaignPayload } from 'lib/types/payloads/streamer-campaigns/brand-awareness'

const getDatesDays = (dates: [Date, Date]): [string, string] => {
  return [
    moment(dates[0]).format('YYYY-MM-DD'),
    moment(dates[1]).format('YYYY-MM-DD'),
  ]
}

interface State {
  isActive: boolean
  isFetchingData: boolean
  streamerId: string | null
  slug: string | null
  title: string
  data: ICampaignAnalyticsDay[]
  campaignDates: [Date, Date]
  currentDates: [Date, Date]
}

export const useCampaignAnalyticsStore = defineStore({
  id: 'campaignAnalytics',

  state: (): State => ({
    isActive: false,
    isFetchingData: false,
    streamerId: null,
    slug: null,
    title: '',
    data: [],
    campaignDates: [new Date(), new Date()],
    currentDates: [new Date(), new Date()],
  }),

  actions: {
    setStreamer (streamerId: string) {
      this.streamerId = streamerId
    },

    setCampaignData (campaign: ICampaignPayload) {
      this.slug = campaign.slug
      this.campaignDates = [
        moment(campaign.start, 'DD.MM.YYYY').toDate(),
        moment(campaign.end, 'DD.MM.YYYY').toDate(),
      ]
      this.currentDates = [...this.campaignDates]
    },

    async fetchData () {
      if (!this.streamerId || !this.slug || !this.currentDates[0] || !this.currentDates[1]) {
        return
      }
      this.isFetchingData = true
      const res = await getAnalyticsData(this.streamerId, this.slug, ...getDatesDays(this.currentDates))
      this.title = res.data.title
      this.data = res.data.data
      this.isFetchingData = false
    },

    async showAnalytics (campaign: ICampaignPayload) {
      this.setCampaignData(campaign)
      await this.fetchData()
      if (this.data.length) {
        this.isActive = true
        document.querySelector('.dashboard-layout__content')?.classList.add('dashboard-layout__content_full')
      }
    },

    closeAnalytics () {
      this.isActive = false
      document.querySelector('.dashboard-layout__content')?.classList.remove('dashboard-layout__content_full')
      this.slug = null
      this.campaignDates = [new Date(), new Date()]
      this.currentDates = [new Date(), new Date()]
    },
  },
})
