import { render, staticRenderFns } from "./Table.vue?vue&type=template&id=2a969232&scoped=true&"
import script from "./Table.vue?vue&type=script&setup=true&lang=ts&"
export * from "./Table.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./Table.vue?vue&type=style&index=0&id=2a969232&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a969232",
  null
  
)

export default component.exports