import { Api } from 'lib/api'
import { ICampaignAnalytics } from "lib/types/campaign-analytics";
import { IResponseData } from "lib/types/response";

export const getAnalyticsData = (
  streamerId: string,
  slug: string,
  start: string,
  end: string
) => {
  return Api.get<IResponseData<ICampaignAnalytics>>(
    `statistic/streamer/${streamerId}/campaign/${slug}/${start}/${end}`
  ).then((res) => res);
};
